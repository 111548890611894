import React from "react";
import { ClientReturn } from "../../assets/icons/ClientReturn";
import { CustomerConfig } from "../../assets/icons/CustomerConfig/CustomerConfig";
import { Debrief } from "../../assets/icons/Debrief/Debrief";
import EventIcon from "../../assets/icons/EventIcon";
import { FileExport } from "../../assets/icons/FileExport/FileExport";
import { Flag } from "../../assets/icons/Flag";
import { Offshore } from "../../assets/icons/Offshore";
import { Order } from "../../assets/icons/Order/Order";
import { Product } from "../../assets/icons/Product";
import { Route } from "../../assets/icons/Route";
import { getToolbox } from "../utils";
const baseUrl = getToolbox();
export const menuData = [
  // {
  //   id: 1,
  //   name: "Event Dashboard",
  //   icon: <EventIcon />,
  //   url: "/event-dashboard",
  //   subMenu: [
  //     {
  //       name: "Events",
  //       url: "/event-dashboard",
  //     },
  //     {
  //       name: "Webhook",
  //       url: "/event-dashboard/webhook",
  //     },
  //     {
  //       name: "Integration",
  //       url: "/event-dashboard/integration",
  //     },
  //     {
  //       name: "Clients",
  //       url: "/event-dashboard/clients",
  //     },
  //   ],
  // },
  {
    id: 2,
    name: "Product",
    icon: <Product />,
    url: "/product",
    subMenu: [
      {
        name: "overview",
        url: "/product",
      },
      {
        name: "add",
        url: "/product/add",
      },
      {
        name: "category",
        url: "/product/category",
      },
    ],
  },

  {
    id: 3,
    name: "Order",
    icon: <Order />,
    url: "/orders",
    pathMatch: true,
    subMenu: [
      {
        name: "overview",
        url: "/orders",
      },
      {
        name: "add",
        url: "/orders/add",
      },
      {
        name: "File Import",
        url: "/orders/file-import",
      },
      {
        name: "Recent Imports",
        url: baseUrl,
        target: true,
      },
      // {
      //   name: "all-reminder",
      //   url: "/order/all-reminder",
      // },
    ],
  },
  {
    id: 4,
    name: "Route",
    icon: <Route />,
    url: "/routes",
    subMenu: [
      {
        name: "overview",
        url: "/routes",
      },
    ],
  },
  {
    id: 5,
    name: "Task",
    icon: <Flag />,
    url: "/flag",
    subMenu: [
      {
        name: "overview",
        url: "/flag",
      },
    ],
  },
  // {
  //   id: 6,
  //   name: "Return",
  //   icon: <ClientReturn />,
  //   url: "/orders/view/return",
  //   subMenu: [
  //     {
  //       name: "overview",
  //       url: "/orders/view/return",
  //     },
  //     // {
  //     //   name: "return order",
  //     //   url: "/returnclient/return/",
  //     // },
  //     {
  //       name: "return after order",
  //       url: "/orders/view/return/overview",
  //     },
  //   ],
  // },
  {
    id: 7,
    name: "Finance",
    icon: <Debrief />,
    url: "/finance",
    subMenu: [
      {
        name: "Debrief",
        url: "/finance",
      },
      {
        name: "Invoice",
        url: "/finance/invoice/ready-for-invoice",
      },
      {
        name: "Outstanding Invoices",
        url: "/finance/invoice/outstanding-invoices",
      },
      {
        name: "Credit Control",
        url: "/finance/invoice/outstanding-invoices/credit-control",
      },
    ],
  },

  // {
  //   id: 8,
  //   name: "File export ",
  //   icon: <FileExport />,
  //   url: "/file-export",
  //   subMenu: [
  //     {
  //       name: "overview",
  //       url: "/file-export",
  //     },
  //   ],
  // },

  // {
  //   id: 9,
  //   name: "Offshore",
  //   icon: <Offshore />,
  //   url: "/offshore",
  //   subMenu: [
  //     {
  //       name: "overview",
  //       url: "/offshore",
  //     },
  //   ],
  // },
  // {
  //   id: 10,
  //   name: "Customer Config",
  //   icon: <CustomerConfig />,
  //   url: "/customer-config",
  //   subMenu: [
  //     {
  //       name: "overview",
  //       url: "/customer-config",
  //     },
  //     {
  //       name: "Supplier ",
  //       url: "/customer-config/supplier",
  //     },
  //   ],
  // },

  // {
  //   id: 5,
  //   name: "Offshore",
  //   icon: <Offshore />,
  //   url: "/offshore",
  //   subMenu: [
  //     {
  //       name: "overview",
  //       url: "/offshore",
  //     },
  //   ],
  // },
];
